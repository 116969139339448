import {RoleCode} from '@core/schemes/role'
import {MasterGuard} from '@core/guards/master.guard'
import {AuthGuard} from '@core/guards/auth.guard'
import {RoleGuard} from '@core/guards/role.guard'

export const hasRolesForRoot = (...guardRoles: RoleCode[]): any => {
  return {
    canActivate: [MasterGuard],
    data: {
      guards: [AuthGuard, RoleGuard],
      root: true,
      guardRoles,
    },
  }
}

export const hasRoles = (...guardRoles: RoleCode[]): any => {
  return {
    canActivate: [RoleGuard],
    data: {
      guardRoles,
    },
  }
}
